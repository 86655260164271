'use client';

import clsx from 'clsx';
import { useKeenSlider } from 'keen-slider/react';
import { useEffect, useMemo, useState } from 'react';

import 'keen-slider/keen-slider.min.css';
import styles from './LogoCarousel.module.scss';
import { LogoCarouselStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { SBImage } from '@/components/ImageService/SBImage';

type LogoCarouselProps = {
    blok: LogoCarouselStoryblok;
};

const KeenSlider: React.FC<LogoCarouselProps> = ({ blok }) => {
    const { images, rtl, speed } = blok;
    
    const autoSlideAnimation = { duration: Number(speed) || 30000, easing: (t: number) => t };

    const [sliderRef] = useKeenSlider<HTMLDivElement>(
        {
            loop: true,
            renderMode: 'performance',
            mode: 'free',
            drag: true,
            rtl: rtl,
            slides: {
                perView: 'auto',
            },
            created(s) {
                s.moveToIdx(5, true, autoSlideAnimation);
            },
            updated(s) {
                s.moveToIdx(s.track.details.abs + 5, true, autoSlideAnimation);
            },
            animationEnded(s) {
                s.moveToIdx(s.track.details.abs + 5, true, autoSlideAnimation);
            },
        },
        [],
    );

    // Duplicate images until there are enough for the slider to endlessly move without blanks
    const imagesToRender = useMemo(() => {
        let imageArray = images;
        while (imageArray?.length <= 12) {
            imageArray = imageArray.concat(images);
        }
        return imageArray;
    }, [images]);

    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, [speed]);

    if (!isClient) {
        return null;
    }

    return (
        <div className={clsx(styles.root)}>
            <div ref={sliderRef} className="keen-slider">
                {imagesToRender?.map((image: any) => (
                    <div key={crypto.randomUUID()} className={clsx('keen-slider__slide', styles.imageWrapper)}>
                        <SBImage image={image} className={styles.image} width={200} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default KeenSlider;
