import { LogoCarouselStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { storyblokEditable } from '@storyblok/react';
import KeenSlider from './component/KeenSlider';

type LogoCarouselProps = {
    blok: LogoCarouselStoryblok;
};

const LogoCarousel: React.FC<LogoCarouselProps> = ({ blok }) => {
    return (
        <div style={{ paddingTop: `${blok?.addPadding || 64}px` }} {...storyblokEditable(blok)}>
            <KeenSlider blok={blok} />
        </div>
    );
};

export default LogoCarousel;
